<template>
  <div class="measure-img">
    <div class="measure-title-l measure-title-com measure-text">小里程方向</div>
    <div class="measure-title-r measure-title-com measure-text">大里程方向</div>

    <div class="measure-left measure-common mar-r13">
      <!-- <img src="@/assets/img/zhw/qian.png" alt="" /> -->
    </div>
    <div class="measure-content flex1">
      <img :src="imgDataItem.img" alt="" />
      <div
        :style="{ left: parseFloat(item.front && item.front <= 1 ? item.front : 0) * 100 + '%', width: parseFloat(item.back || 0) - parseFloat(item.front || 0) > 1 ? 1 : (parseFloat(item.back || 0) - parseFloat(item.front || 0)) * 100 + '%' }"
        class="measure-slider measure-text"
        v-for="(item, index) in imgDataItem.list"
        :key="index"
      >
        <div class="measure-slide-text-l" v-if="item.frontName">{{ item.frontName }}</div>
        <div class="measure-slide-text-r" v-if="item.backName">{{ item.backName }}</div>
        <div class="measure-slide-text-c" v-if="item.name">{{ item.name }}</div>
        <div class="measure-slide-text-c" v-if="item.profileName">{{ item.profileName }}</div>
      </div>
    </div>
    <div class="measure-right measure-common mar-l13">
      <!-- <img src="@/assets/img/zhw/hou.png" alt="" /> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "measureImg",
  props: ["imgData"],

  watch: {
    imgData: {
      handler(n, o) {
        console.log("传过来的图例数据watch", this.imgData);
        this.$nextTick(() => {
          this.imgDataItem = n;
          this.$forceUpdate();
        });
      },
      deep: true
    }
  },
  created() {
    console.log("传过来的图例数据", this.imgData);
    this.$nextTick(() => {
      this.imgDataItem = this.imgData;
      this.$forceUpdate();
    });
  },
  data() {
    return {
      imgDataItem: {}
    };
  }
  //   methods: {
  //     getData(id) {
  //       this.$axios
  //         .get(`${this.baseURL}stress/target/info/${id}`)
  //         .then((res) => {
  //           console.log("图数据", res);
  //           if (res.data && res.data.errCode == "200") {
  //             this.imgData = res.data.data || {};
  //             this.$emit("getImgData", this.imgData);
  //           }
  //         })
  //         .catch((err) => {});
  //     }
  //   }
};
</script>

<style scoped lang="scss">
.measure-img {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  position: relative;
  .measure-common {
    width: 22px;

    img {
      width: 100%;
    }
  }
  .measure-content {
    position: relative;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
    .measure-slider {
      position: absolute;
      opacity: 0.75;
      background-image: linear-gradient(180deg, rgba(65, 131, 210, 0.08) 0%, rgba(46, 142, 245, 0.52) 50%, rgba(50, 197, 255, 0.08) 100%);
      z-index: 3;
      top: 0;
      bottom: 0;
      .measure-slide-text-l,
      .measure-slide-text-c,
      .measure-slide-text-r {
        position: absolute;
        bottom: 0;
        width: 90px;
      }
      .measure-slide-text-r {
        right: -80px;
        text-align: left;
      }
      .measure-slide-text-l {
        left: -80px;
        text-align: right;
      }
      .measure-slide-text-c {
        left: 50%;
        margin-left: -45px;
        text-align: center;
      }
    }
  }
}
.mar-r13 {
  margin-right: 13px;
}
.mar-l13 {
  margin-left: 13px;
}
.measure-title-com {
  position: absolute;

  top: 5;
  z-index: 5;
}
.measure-title-l {
  left: 10px;
}
.measure-title-r {
  right: 10px;
}

.measure-text {
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.62);
}
</style>
